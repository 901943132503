<div class="content-login">
        <img class="background" *ngIf="!configuration || !configuration.login_image" src="assets/images/background_login.png">
        <img class="background" *ngIf="configuration && configuration.login_image" [src]="configuration.login_image">
        <div class="over-image"></div>


        <form [formGroup]="formValidation">
            <section class="form">
                <img src="assets/images/logo_white.png" alt="logo">
                <div class="text-welcome">Entre com seus dados</div>
                <div class="input input-dark">
                    <input class="input-box" type="email" placeholder="E-mail" [class.input-box-error]="submitted && (errorCtr.username.errors?.required || errorCtr.username.errors?.pattern)"
                    formControlName="username" (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">

                    <div class="error-form" *ngIf="submitted && errorCtr.username.errors?.required">
                        O campo e-mail é obrigatório
                    </div>
                    <div class="error-form" *ngIf="submitted && errorCtr.username.errors?.pattern">
                        Preencha corretamente o campo
                    </div>
                </div>

                <div class="password">
                    <div class="input input-dark">
                        <input class="input-box" type="password" (focus)="focus('focus', 'password')" [class.input-box-error]="submitted && errorCtr.password.errors?.required"
                        (blur)="focus('unfocus', 'password')" formControlName="password" placeholder="Senha">

                        <span class="error-form" *ngIf="submitted && errorCtr.password.errors?.required">
                            O campo senha é obrigatório
                        </span>
                    </div>
                    <span class="forgot-password" [routerLink]="'/recuperar-senha'">Esqueceu sua senha?</span>
                </div>

                <button class="button-primary" (click)="doCaptchValidation()">Entrar</button>

                <span class="subscribe-now" *ngIf="configuration && configuration.show_link_store && configuration.link_store || true">Ainda não tem uma
                    conta ou a sua expirou? <a (click)="openSalesPage()">Assine já</a></span>
            </section>
        </form>
</div>