<app-simple-header></app-simple-header>

<div class="content">
    <div class="title">
        Consistência
    </div>
    <div class="over-text steps">
        Para que você possa ser aprovado o seu nível de consistência precisa ser igual ou estar acima de 80%. O nível de consistência é calculado da seguinte maneira:
        <br><br>
        Obtenha os 4 melhores dias operados: <br>
        PRIMEIRO MELHOR DIA <br>
        SEGUNDO MELHOR DIA <br>
        TERCEIRO MELHOR DIA <br>
        QUARTO MELHOR DIA <br>
        <br><br>
        A soma entre o SEGUNDO, TERCEIRO e QUARTO melhor dia precisa ser equivalente a pelo menos 80% do resultado do seu PRIMEIRO MELHOR DIA
    </div>
    <br><br>
    <div class="title">
        Exemplo
    </div>

    <div class="over-text steps">
        Suponhamos que o resultado dos seus melhores 4 melhores dias são:
        <br><br>
        PRIMEIRO MELHOR DIA - <strong>$1.000,00</strong><br>
        SEGUNDO MELHOR DIA - <strong>$300,00</strong> <br>
        TERCEIRO MELHOR DIA - <strong>$100,00</strong> <br>
        QUARTO MELHOR DIA - <strong>$500,00</strong> <br>
        <br><br>

        O seu melhor dia foi positivo em $1.000,00, para alcançar o nível de pelo menos 80% de consistência, a soma do seu segundo, terceiro e quarto melhor dia precisa ser pelo menos 800,00. Ou seja, 80% do seu melhor dia. Vamos calcular:
        
        <br>
        300,00 + 100,00 + 500,00 = <strong>900,00</strong>

        <br><br>

        Neste exemplo a soma entre o meu segundo, terceiro e quarto dia melhor dia foi de $900,00. Portanto, através desse cálculo chegamos a conclusão que o meu nível de consistência é de 90%.
    </div>
</div>