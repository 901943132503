import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';

@Injectable({
  providedIn: 'root'
})
export class InputFormsService {

  constructor(
    private maskPipe: MaskPipe,
  ) { }

  validator(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();

      if (controle instanceof UntypedFormGroup) {
        this.validator(controle);
      }
    });
  }

  setInputFocus(document: Document, element: string, formValidation: UntypedFormGroup, submitted: boolean) {

    var label = document.getElementById('label-' + element);

      // if (label)
      //   label.classList.add('color-primary');
  }

  unCheckFocus(document: Document, element: string) {
    var label = document.getElementById('label-' + element);

    // if (label) {
    //   label.classList.remove('color-primary');
    // }
  }

  validateDocument(control: AbstractControl): ValidationErrors | null {
    const cpf: string = control.value;
    let digits = Array.from(cpf);
    
    if(digits.length != 11 ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999' ||
      cpf == '00000000000'
    )
      return {cpfInvalid: true};

    let d1 = 0, d2 = 0;
    for(let i=0; i<9; i++) {
      d1 += Number(digits[i]) * (10-i);
      d2 += Number(digits[i]) * (11-i);
    }
    d2 += Number(digits[9]) * 2;

    d1*= 10;
    d2*= 10;

    if((d1%11)%10 != Number(digits[9]))
      return {cpfInvalid: true}

    if((d2%11)%10 != Number(digits[10]))
      return {cpfInvalid: true}

    return null;
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: UntypedFormGroup): {[key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    }
  }

  updateMask(formValidation, field, event) {

    switch (field) {
      case "document_number":
        formValidation.controls.document_number.setValue(this.maskPipe.transform(event.currentTarget.value, '000.000.000-00'));
        break;
      case "phone_number":
        formValidation.controls.phone_number.setValue(this.maskPipe.transform(event.currentTarget.value, '(00) 00000-0000'));
        break;
      case "birth_date":
        formValidation.controls.birth_date.setValue(this.maskPipe.transform(event.currentTarget.value, '00/00/0000'));
        break;
      case "hour":
        formValidation.controls.hour.setValue(this.maskPipe.transform(event.currentTarget.value, '00:00'));
        break;
      case "date":
        formValidation.controls.date.setValue(this.maskPipe.transform(event.currentTarget.value, '00/00/0000 00:00:00'));
        break;
      case "money":
        formValidation.controls.value.setValue(this.money(event.currentTarget.value));
        break;
    }
  }

  money(value: string) {
    let negative = false, newNegative = false;

    negative = value.startsWith('-'); // começar com sinal de menos indica que é negativo
    newNegative = value.startsWith('0') && value.endsWith('-'); //iniciar com zero e terminar com sinal de menos indica a intenção de inserir um novo negativo a partir de 0,00

    if (value) {
      value = value.replace(/\D/g, ''); // remove todos caracteres que não são digitos
      value = value.replace(/^([0]*)/, ''); // remove todos os zeros no inicio

      if (value.length == 0) {
        if (negative || newNegative) return '-';
        return '0,00';
      }
      if (value.length == 1) {
        return negative ? '-0,0' + value : '0,0' + value;
      }
      if (value.length == 2) {
        return negative ? '-0,' + value : '0,' + value;
      } else {
        value = value.replace(/(\d)(\d{2})$/, '$1,$2'); // insere a virgula ao final do numero
        value = value.replace(/(\d)(\d{3},\d{2})$/, '$1.$2'); //insere o ponto ao primeiro grupo de milhar
        value = value.replace(/(\d)(\d{3}.\d{3},\d{2})$/, '$1.$2'); //insere o ponto ao primeiro grupo de milhao
      }

      return negative ? '-' + value : value;
    }

    return '0,00';
  }
}
